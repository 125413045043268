import React from 'react';
import BMI from '../Anthro/BMI';
import { Pie } from 'react-chartjs-2';
import { useUser } from '../../Context/User/UserContext';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {
    const { foodEntries, analytics } = useUser();
    const pieData = {
        labels: analytics?.calculationBreakdown.map(item => item._id),
        datasets: [
            {
                label: 'Calculation Breakdown',
                data: analytics?.calculationBreakdown.map(item => item.count),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0'], // Add more colors as needed
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0'],
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };

    const quotes = [
        "Let food be thy medicine and medicine be thy food. - Hippocrates",
        "You are what you eat, so don’t be fast, cheap, easy, or fake. - Foodimetric",
        "The food you eat can be either the safest and most powerful form of medicine or the slowest form of poison. - Ann Wigmore",
        "A healthy outside starts from the inside. - Robert Urich",
        "To eat is a necessity, but to eat intelligently is an art. - François de La Rochefoucauld",
        "Eat well, live well, be well. - Foodimetric",
        "Take care of your body. It’s the only place you have to live. - Jim Rohn",
        "When diet is wrong, medicine is of no use. When diet is correct, medicine is of no need. - Ayurvedic Proverb",
        "Your diet is a bank account. Good food choices are good investments. - Bethenny Frankel",
        "Eat to nourish your body, not to feed your emotions. - Foodimetric",
        "The groundwork of all happiness is health. - Leigh Hunt",
        "Healthy eating is a way of life, so it’s important to establish routines that are simple, realistically, and ultimately livable. - Horace",
        "An apple a day keeps the doctor away. - Proverb",
        "Don’t dig your grave with your own knife and fork. - English Proverb",
        "Eat food, not too much, mostly plants. - Michael Pollan",
        "Every time you eat is an opportunity to nourish your body. - Foodimetric",
        "He who has health has hope, and he who has hope has everything. - Arabian Proverb",
        "Food is not just fuel. It’s about family, community, and identity. - Michael Pollan",
        "Eat clean, stay fit, and have a burger to stay sane. - Gigi Hadid",
        "Your body is a temple, but only if you treat it as one. - Astrid Alauda",
        "Eating well is a form of self-respect. - Foodimetric",
        "The secret of success in life is to eat what you like and let the food fight it out inside. - Mark Twain",
        "If you keep good food in your fridge, you will eat good food. - Errick McAdams",
        "Good nutrition creates health in all areas of our existence. - T. Colin Campbell",
        "To lengthen thy life, lessen thy meals. - Benjamin Franklin",
        "We are living in a world today where lemonade is made from artificial flavors and furniture polish is made from real lemons. - Alfred Newman",
        "Take control of your habits. Take control of your diet. - Foodimetric",
        "Eat less from a box and more from the earth. - Foodimetric",
        "The greatest wealth is health. - Virgil",
        "Moderation. Small helpings. Sample a little bit of everything. These are the secrets of happiness and good health. - Julia Child",
    ];
    // console.log(foodEntries);

    const today = new Date().getDate(); // Get day of the month (1-31)
    const quoteOfTheDay = quotes[today % quotes.length]; // Rotate through quotes

    return (
        <div className="col-span-12 w-full">
            <Helmet>
                <title>Your Foodimetric Dashboard - Track Nutrition & Health</title>
                <meta name="description"
                    content="Foodimetric is your AI-powered nutrition companion, helping you track and improve your diet with advanced tools. Explore our food database, nutrient search, and BMI calculator—trusted across Africa and Nigeria for smarter health choices." />
            </Helmet>
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 mb-2 rounded-md">
                <p className="font-semibold">Important:</p>
                <p className="text-sm">
                    For existing users, users before <span className="text-red-600 font-bold">07-03-2025</span> who are <strong>Nutrition professionals</strong>, kindly go to the{" "}
                    <Link to="/dashboard/setting" className="text-blue-600 underline">Settings</Link> page to update your profession
                    in order to unlock features tailored for your expertise.
                    <br />
                    If you are <strong>not a professional</strong>, please select "Others."
                    This will grant you access to functionality designed to support your nutrition journey and goals.
                    <strong> After updating, remember to save your changes.</strong>
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
                <div className="p-4 bg-blue-100 rounded-lg shadow-md">
                    <h3 className="text-sm text-gray-600 font-heading-font">Diary Tracked</h3>
                    <p className="text-xl font-semibold text-blue-600 font-base-font">{analytics?.totalFoodLogs || 0}</p>
                </div>
                <div className="p-4 bg-green-100 rounded-lg shadow-md">
                    <h3 className="text-sm text-gray-600 font-heading-font">Calculations</h3>
                    <p className="text-xl font-semibold text-green-600 font-base-font">{analytics?.totalCalculations || 0}</p>
                </div>
                <div className="p-4 bg-yellow-100 rounded-lg shadow-md">
                    <h3 className="text-sm text-gray-600 font-heading-font">Most Used</h3>
                    <p className="text-xl font-semibold text-yellow-600 font-base-font">{analytics?.mostUsedCalculator || 0}</p>
                </div>
                <div className="p-4 bg-purple-100 rounded-lg shadow-md">
                    <h3 className="text-sm text-gray-600 font-heading-font">Usage</h3>
                    <p className="text-xl font-semibold text-purple-600 font-base-font">{analytics?.platformUsage || 0}</p>
                </div>
            </div>

            {/* Recent Meals Section */}
            {foodEntries.length > 0 && <div className="mt-4 bg-white shadow-md rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Recent Meals</h3>
                <div className="space-y-4">
                    {foodEntries
                        .slice()
                        .reverse() // Ensure most recent entries come first
                        .slice(0, 3).map((meal, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between p-4 bg-gray-50 border rounded-lg shadow-sm"
                            >
                                <div>
                                    <p className="text-sm font-medium text-gray-700 font-heading-font break-words">{meal.foodEaten}</p>
                                    <p className="text-xs text-gray-500 font-base-font italic font-medium">{meal.quantity}</p>
                                </div>
                                <div className="text-sm font-semibold text-gray-800 font-heading-font">{meal.time}</div>
                            </div>
                        ))}
                </div>
            </div>}
            {/* Actions Section */}
            <div className='flex flex-col gap-4 md:flex-row'>
                <div className='w-full md:w-1/3'>
                    {/* Nutrition Quote of the Day */}
                    <div className="mt-8 p-6 bg-gray-50 border-t rounded-lg shadow-sm w-full h-fit">
                        <h3 className="text-lg font-semibold text-gray-800 font-heading-font">Nutrition Quote of the Day</h3>
                        <p className="text-sm text-gray-600 mt-2 italic font-base-font">
                            "{quoteOfTheDay}"
                        </p>
                    </div>

                    {/* chart, pie chart */}
                    <div className="mt-6 p-6 pb-12 bg-white border-t rounded-lg shadow-sm w-full h-80">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4 font-heading-font">Calculation Breakdown</h3>
                        {analytics?.calculationBreakdown && analytics?.calculationBreakdown.length > 0 ? (
                            <Pie data={pieData} options={pieOptions} />
                        ) : (
                            <p className='font-base-font'>No calculations performed yet.</p>
                        )}
                    </div>
                    <div className="mt-6 p-6 bg-gray-50 border-t rounded-lg shadow-sm w-full h-fit">
                        <h3 className="text-lg font-semibold text-gray-800 font-heading-font">What’s Next?</h3>
                        <p className="text-sm text-gray-600 mt-2 font-base-font">
                            Stay tuned for upcoming features like personalized AI insights and advanced nutrient tracking!
                        </p>
                    </div>

                </div>
                <div className='w-full md:w-2/3'>
                    <BMI islandingPage={false} />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
