import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Layout from '../Components/Layout'


const Error = () => {
    return (
        <Layout>
            <Helmet>
                <title>404 - Page Not Found | Foodimetric</title>
                <meta name="description"
                    content="Oops! The page you're looking for doesn't exist. Return to Foodimetric's homepage to explore nutrition tools, food databases, and health calculators." />
            </Helmet>
            <section className="relative py-[80px] sm:py-[50px] overflow-hidden z-1 text-center">
                <h2 className="text-4xl font-normal font-heading-font mb-5 lg:text-4xl sm:text-3xl sr-only">Oops! The page you're looking for doesn't exist. Return to Foodimetric's homepage to explore nutrition tools, food databases, and health calculators.</h2>

                <div className="container mx-auto px-4">
                    <div className="flex flex-col items-center">
                        {/* Error Image */}
                        <img
                            className="max-w-[80%] md:max-w-[60%] mx-auto"
                            src="assets/images/error-404.png"
                            alt="404 Error"
                        />

                        {/* Text Content */}
                        <div className="mt-10 sm:mt-6 px-6 md:px-12 lg:px-24 text-center">
                            <h3 className="text-[#062856] text-[28px] sm:text-[24px] font-semibold">
                                Oops! Page Not Found!
                            </h3>
                            <p className="text-[#666] text-[16px] sm:text-[14px] leading-[24px] mt-4">
                                We’re sorry, but we can’t seem to find the page you requested.
                                This might be because you typed the web address incorrectly.
                            </p>

                            {/* Back to Home Button */}
                            <Link
                                to="/"
                                className="mt-6 inline-block bg-[#147e03] text-white px-6 py-3 rounded-lg text-[16px] sm:text-[14px] font-medium shadow-md transition duration-300 hover:bg-green-700"
                            >
                                Back to Home
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Error;