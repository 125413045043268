import React from 'react';
import { Outlet, useLocation } from 'react-router';
import AntroNav from '../../Components/Nav/AntroNav';
import AntroHeader from '../../Components/Headers/AntroHeader';
import { Helmet } from 'react-helmet-async';

const AnthroLayout = () => {
    const location = useLocation();

    const pageTitle = getPageTitle(location.pathname);
    const formulaName = getFormulaName(location.pathname);


    // console.log(user);

    function getPageTitle(pathname) {
        switch (pathname) {
            case '/anthro/BMI':
                return 'BMI Calculator - Body Mass Index for Adults & Children';
            case '/anthro/IBW':
                return 'IBW Calculator - Ideal Body Weight for Men & Women';
            case '/anthro/WHR':
                return 'Waist to Hip Ratio Calculator - Health Risk Assessment';
            case '/anthro/BMI-age':
                return 'BMI for Age Calculator - Child & Teen Growth Chart';
            case '/anthro/BMR':
                return 'BMR Calculator - Basal Metabolic Rate & Daily Calories';
            case '/anthro/EER':
                return 'Estimated Energy Requirement (EER) Calculator - Daily Caloric Needs';
            case '/anthro/EE':
                return 'Energy Expenditure Calculator - Calorie Burn Per Activity';
            case '/anthro/Weight-age':
                return 'Weight for Age Growth Chart - Child & Teen Percentiles';
            case '/anthro/Height-age':
                return 'Height for Age Growth Chart - Child & Teen Development';
            case '/anthro/Weight-Height':
                return 'Weight for Height Calculator - Growth & Health Analysis';
            case '/anthro/Water-intake':
                return 'Daily Water Intake Calculator - Hydration Recommendations';
            default:
                return 'Anthropometric Health Calculators - BMI, IBW, BMR & More';
        }
    }

    function getFormulaName(pathname) {
        switch (pathname) {
            case '/anthro/BMI':
                return 'Body Mass Index (BMI) Formula - Weight & Height Ratio';
            case '/anthro/IBW':
                return 'B. J. Devine Formula (1974) - Ideal Body Weight Calculation';
            case '/anthro/WHR':
                return 'Waist to Hip Ratio Formula - Health Risk Indicator';
            case '/anthro/BMI-age':
                return 'BMI for Age Formula - Growth Percentile Calculation';
            case '/anthro/BMR':
                return 'Revised Harris-Benedict Equation - Basal Metabolic Rate (BMR)';
            case '/anthro/EER':
                return 'Estimated Energy Requirement (EER) Formula - Daily Caloric Needs';
            case '/anthro/EE':
                return 'Mifflin-St Jeor Equation - Energy Expenditure Calculation';
            case '/anthro/Weight-age':
                return 'Weight for Age Formula - Growth & Development Assessment';
            case '/anthro/Height-age':
                return 'Height for Age Formula - Child & Teen Growth Chart';
            case '/anthro/Weight-Height':
                return 'Weight for Height Formula - Ideal Growth Standards';
            case '/anthro/Water-intake':
                return 'Water Intake Recommendation Formula - Daily Hydration Needs';
            default:
                return null;
        }
    }
    return (
        <main className='flex max-h-screen'>
            <Helmet>
                <title>Track your health and Find Your Body Mass Index | Foodimetric</title>
                <meta name="description"
                    content="Foodimetric is your AI-powered nutrition companion, helping you track and improve your diet with advanced tools. Explore our food database, nutrient search, and BMI calculator—trusted across Africa and Nigeria for smarter health choices." />
            </Helmet>
            <div className=''>
                <AntroNav />
            </div>
            <div className='ml-60 max-h-screen overflow-auto max-w-6xl w-[100vw] mx-auto xs:ml-0 md:ml-60'>
                <AntroHeader title={pageTitle} />
                <div className='bg-gray-100'>
                    <div className='px-8 pt-16 '>
                        <Outlet />
                    </div>
                </div>
                <footer className="bg-gray-200 text-center text-gray-700 text-sm py-4 mt-8 font-base-font">
                    <p>
                        Formula Used: <strong>{formulaName}</strong>
                        {formulaName && " - Accurate health & fitness calculations for better well-being."}
                    </p>
                </footer>
            </div>
        </main>
    )
}

export default AnthroLayout;