import React from 'react';
import AboutUs from '../Components/sections/About';
import Faq from '../Components/sections/Faq';
import Team from '../Components/sections/Team';
import { Helmet } from 'react-helmet-async';
import Layout from '../Components/Layout';

const About = () => {
    return (
        <Layout>
            <Helmet>
                <title>About Foodimetric - AI-Powered Nutrition & Health Insights</title>
                <meta name="description"
                    content="Foodimetric is your AI-powered nutrition companion, helping you track and improve your diet with advanced tools. Explore our food database, nutrient search, and BMI calculator—trusted across Africa and Nigeria for smarter health choices." />
            </Helmet>
            <main>
                <AboutUs padding={'20px'} />
                <Team />
                <Faq page="about" />
            </main>
        </Layout>
    );
}

export default About;